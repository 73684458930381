import Dashboard from './Home';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import RefundPage from './Pages/RefundPolicy';
import TermsPage from './Pages/TermsCondition';
import PrivacyPage from './Pages/PrivacyPolicy';
import AboutUs from './Pages/About';
import ContactUs from './Pages/Contact';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {
  return (
    <div>
  <Router>
    <Routes>
      <Route path="/about" element={<AboutUs />} />
      <Route path="/refund" element={<RefundPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/contact" element={<ContactUs/>} />
      <Route path="/" element={<Dashboard/>} /> 
    </Routes>
  </Router>
    </div>
  );
}

export default App;
