// src/Components/Pages/PrivacyPolicy.js
import React from 'react';
import styles from '../Dashboard.module.css'; // Ensure this path is correct
import Sidebar from '../Components/Module/Sidebar';
import Footer from '../Components/Module/Footer';

const PrivacyPolicy = () => {
  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <div className={styles.mainContent}>
        <h1>Privacy Policy</h1>
        <div className={styles.policyContent}>
          <p>At Jolttbook, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit or make a purchase from our website. By using our services, you agree to the collection and use of information in accordance with this policy.</p>
          
          <h2>1. Information We Collect</h2>
          <h3>Personal Data</h3>
          <p>When you visit our site or make a purchase, we may collect the following personal information:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Payment information (for paid contests)</li>
            <li>Billing and Shipping Address</li>
            <li>Order History</li>
          </ul>
          <h3>Automatically Collected Information</h3>
          <p>We also collect some data automatically when you use our website, such as:</p>
          <ul>
            <li>Device information (e.g., device model, OS version)</li>
            <li>IP address</li>
            <li>Browser Type</li>
            <li>Cookies and Usage Data</li>
          </ul>
          
          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Provide, operate, and maintain the App</li>
            <li>Process contest entries and payments</li>
            <li>Communicate with you regarding your account, contests, and updates</li>
            <li>Personalize your experience on the App</li>
            <li>Improve our services and develop new features</li>
            <li>Ensure the security and integrity of the App</li>
            <li>Comply with legal obligations</li>
          </ul>
          
          <h2>3. Sharing Your Information</h2>
          <p>We do not share your personal information with third parties, except in the following cases:</p>
          <ul>
            <li><strong>Service Providers:</strong> We may share information with third-party service providers who assist us in operating the App (e.g., payment processors, hosting services). These providers are required to protect your information.</li>
            <li><strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations, enforce our terms of service, or protect the rights, property, or safety of Jolttbook, our users, or others.</li>
            <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred to the acquiring entity.</li>
          </ul>
          
          <h2>4. Security of Your Information</h2>
          <p>We take reasonable measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet is completely secure.</p>
          
          <h2>5. Data Retention</h2>
          <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
          
          <h2>6. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access and update your personal information.</li>
            <li>Request the deletion of your personal information.</li>
            <li>Opt-out of marketing communications.</li>
            <li>Restrict the processing of your information.</li>
            <li>Object to the use of your information in certain circumstances.</li>
          </ul>
          <p>To exercise these rights, please contact us at <a href="mailto:jolttbook@gmail.com">jolttbook@gmail.com</a></p>
          
          <h2>7. Children's Privacy</h2>
          <p>Jolttbook does not knowingly collect or solicit personal information from anyone under the age of 13. If you believe that a child under 13 has provided us with personal information, please contact us, and we will take steps to delete the information.</p>
          
          <h2>8. Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review the policy periodically. Your continued use of our services after changes have been made will be deemed as acceptance of those changes.</p>
          
          <h2>9. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <p>Email: <a href="mailto:jolttbook@gmail.com">jolttbook@gmail.com</a></p>
          <p>Address: 438/74 Patel Marg, Ramgarh, Ghaziabad, Uttar Prades, 201001</p>
          
        </div>
      </div>
    
    </div>
  );
};

export default PrivacyPolicy;
