// src/Components/Pages/AboutUs.js
import React from 'react';
import styles from '../Dashboard.module.css';
import Sidebar from '../Components/Module/Sidebar';

const AboutUs = () => {
  return (
    <div className={styles.dashboard}>
    <Sidebar/>
    <div className={styles.mainContent}>
      <h1>About Us</h1>
      <div className={styles.policyContent}>
        <p>Welcome to Jolttbook, your ultimate destination for eBooks across a wide range of genres and categories. Whether you’re a casual reader, a literature enthusiast, or someone seeking educational resources, Jolttbook offers a seamless and enjoyable reading experience tailored to every reader’s needs.</p>
        
        <h2>Who We Are?</h2>
        <p>Founded by a team of passionate book lovers, Jolttbook was created with a vision to make reading more accessible and convenient for everyone. We believe in the power of stories, knowledge, and creativity, and our platform serves as a bridge between readers and the endless world of books. Our goal is to curate a diverse library of eBooks, ensuring that there’s something for everyone, from timeless classics to the latest bestsellers.</p>
        {/* <ul>
          <li><strong>Duplicate Purchase:</strong> If you accidentally purchased the same eBook more than once, we will refund the duplicate purchase.</li>
          <li><strong>Technical Issues:</strong> If you experience technical difficulties with the eBook that prevent access or readability, and we are unable to resolve the issue, we will consider issuing a refund.</li>
          <li><strong>Incorrect Purchase:</strong> If you mistakenly purchased the wrong eBook, and have not downloaded it yet, you may request a refund within 24 hours of purchase.</li>
        </ul> */}
        
        <h2>What we offer</h2>
        <p>A Diverse Collection: Explore a wide range of eBooks in genres like fiction, non-fiction, self-help, education, fantasy, romance, and much more.</p>
        <ul>
          <li>Easy and Instant Access: Our digital platform allows you to instantly download and enjoy eBooks from anywhere in the world, at any time.</li>
          <li>Affordable Prices: We strive to provide our readers with high-quality eBooks at competitive prices, so great stories are always within reach.</li>
          <li>Personalized Recommendations: With our recommendation system, we help you discover new books based on your reading preferences and history.</li>
        </ul>
        
        <h2>Why Choose Jolttbook?</h2>
        
        <ul>
          <li>Convenience: Jolttbook makes it easier than ever to buy and download eBooks directly to your device. No waiting, no shipping—just instant access to your favorite reads.</li>
          <li>Quality: We work closely with authors, publishers, and creators to ensure that every eBook on our platform meets the highest standards of quality.</li>
          <li>Customer-Centric: Our user-friendly interface, personalized customer service, and clear policies are designed with your satisfaction in mind.</li>
          <li>Global Reach: Wherever you are in the world, Jolttbook connects you with thousands of eBooks at your fingertips.</li>
        </ul>
       
        
        <h2>Our Mission</h2>
        <p>Our mission is simple: to ignite a passion for reading by providing a digital library where stories, ideas, and knowledge come to life. We believe that reading should be enjoyable, affordable, and accessible to everyone, which is why we’re constantly expanding our collection and enhancing our platform to suit our readers’ needs.</p>
        
        <h2>Join the Jolttbook Community</h2>
        <p>At Jolttbook, we don’t just sell eBooks—we build a community of readers and lifelong learners. Sign up today and start exploring new worlds, one page at a time. Whether you’re diving into a gripping novel or exploring a new subject, we’re here to support your reading journey.</p>

        <h2>Conclusion</h2>
        <p>This About Us section highlights the values and offerings of Jolttbook, aiming to connect with potential customers and provide them with an understanding of what sets the platform apart.</p>
      </div>
    </div>
  
  </div>
  );
};

export default AboutUs;
