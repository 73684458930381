// RefundPage.js
import React from 'react';
import styles from '../Dashboard.module.css';
import Sidebar from '../Components/Module/Sidebar';

const RefundPage = () => (
  <div className={styles.dashboard}>
    <Sidebar/>
    <div className={styles.mainContent}>
      <h1>Refund Policy</h1>
      <div className={styles.policyContent}>
        <p>At Jolttbook, customer satisfaction is our top priority. We strive to provide high-quality eBooks and a seamless purchasing experience. However, due to the nature of digital products, our refund policy has certain limitations. Please read the following carefully.</p>
        
        <h2>1. Digital Products and Refund Eligibility</h2>
        <p>Since eBooks are digital products that are delivered instantly upon purchase, we generally do not offer refunds once an eBook has been downloaded. Refunds will only be granted under specific circumstances, including:</p>
        <ul>
          <li><strong>Duplicate Purchase:</strong> If you accidentally purchased the same eBook more than once, we will refund the duplicate purchase.</li>
          <li><strong>Technical Issues:</strong> If you experience technical difficulties with the eBook that prevent access or readability, and we are unable to resolve the issue, we will consider issuing a refund.</li>
          <li><strong>Incorrect Purchase:</strong> If you mistakenly purchased the wrong eBook, and have not downloaded it yet, you may request a refund within 24 hours of purchase.</li>
        </ul>
        
        <h2>2. Non-Refundable Cases</h2>
        <p>Refunds will not be provided in the following cases:</p>
        <ul>
          <li>You changed your mind after purchasing the eBook.</li>
          <li>You failed to read the product description or compatibility requirements.</li>
          <li>You did not like the content or formatting of the eBook after purchasing it.</li>
          <li>The eBook was successfully downloaded and accessed.</li>
        </ul>
        
        <h2>3. How to Request a Refund</h2>
        <p>To request a refund, please contact our support team at <a href="mailto:jolttbook@gmail.com">jolttbook@gmail.com</a> with the following information:</p>
        <ul>
          <li>Your order number.</li>
          <li>The reason for requesting a refund.</li>
          <li>Screenshots or details of the technical issue (if applicable).</li>
        </ul>
        <p>Our team will review your request and respond within 3-5 business days. If your refund request is approved, the refund will be processed to your original payment method within 5-10 business days.</p>
        
        <h2>4. Exchanges</h2>
        <p>If you purchased the wrong eBook and have not downloaded it, you may request an exchange for the correct title within 24 hours of purchase. Exchanges are subject to availability and approval by our support team.</p>
        
        <h2>5. Contact Information</h2>
        <p>If you have any questions or need assistance with your purchase or refund request, please contact us at: <a href="mailto:jolttbook@gmail.com">jolttbook@gmail.com</a>.</p>
      </div>
    </div>
  
  </div>
);

export default RefundPage;
