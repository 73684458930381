// src/Components/Dashboard.js

import React, { useState } from 'react';
import styles from './Dashboard.module.css';
import home from './Components/icons/home.png';
import about from './Components/icons/about.png';
import contact from './Components/icons/contact.png';
import refund from './Components/icons/refund.png';
import terms from './Components/icons/terms.png';
import privacy from './Components/icons/privacy.png';
import { Link } from 'react-router-dom';
import b1 from './Components/Images/b1.png';
import b2 from './Components/Images/B2.png';
import b3 from './Components/Images/B3.png';
import b4 from './Components/Images/b4.png';
import logo from './Components/Images/logo.png'

const Dashboard = () => {
  const [selectedBook, setSelectedBook] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleBookClick = (book) => {
    setSelectedBook(book);
    setIsFormVisible(false); // Reset form visibility if a new book is selected
  };

  return (
    <div className={styles.dashboard}>
      <Sidebarr />
      <MainContent onBookClick={handleBookClick} />
      <RightPanel 
        selectedBook={selectedBook} 
        isFormVisible={isFormVisible} 
        setIsFormVisible={setIsFormVisible} 
      />
    </div>
  );
};

const Sidebarr = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div 
      className={`${styles.sidebar} ${isExpanded ? styles.expanded : ''}`} 
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Link to="/" className={styles.menuItem}>
        <img className={styles.menuImg} src={home} alt="/" /> {isExpanded && <span className={styles.menuText}>Home</span>}
      </Link>
      <Link to="/about" className={styles.menuItem}>
        <img className={styles.menuImg} src={about} alt="About Us" /> {isExpanded && <span className={styles.menuText}>About Us</span>}
      </Link>
      <Link to="/refund" className={styles.menuItem}>
        <img className={styles.menuImg} src={refund} alt="Refund Policy" /> {isExpanded && <span className={styles.menuText}>Refund Policy</span>}
      </Link>
      <Link to="/terms" className={styles.menuItem}>
        <img className={styles.menuImg} src={terms} alt="Terms & Conditions" /> {isExpanded && <span className={styles.menuText}>Terms & Conditions</span>}
      </Link>
      <Link to="/privacy" className={styles.menuItem}>
        <img className={styles.menuImg} src={privacy} alt="Privacy Policy" /> {isExpanded && <span className={styles.menuText}>Privacy Policy</span>}
      </Link>
      <Link to="/contact" className={styles.menuItem}>
        <img className={styles.menuImg} src={contact} alt="Contact Us" /> {isExpanded && <span className={styles.menuText}>Contact Us</span>}
      </Link>
      <div className={styles.profilePic}>
      <img className={styles.logo} src={logo} />
        {isExpanded}
      </div>
    </div>
  );
};

const MainContent = ({ onBookClick }) => (
  <div className={styles.mainContent}>
    <h1>Welcome to Jolttbook</h1>
    <p>Enhance your experience of Reading ebooks</p>
    
    <div className={styles.stats}>
      <StatCard title="Total Sales" value="123" color="#6ccf76" />
      <StatCard title="Happy Readers" value="209" color="#ffb26b" />
      <StatCard title="Total Books" value="24" color="#7e87ff" />
      <StatCard title="Total Subjects" value="10" color="#a4c7f4" />
    </div>
    
    <div className={styles.recentActivity}>
      <h2>Available Books</h2>
      <ActivityList onBookClick={onBookClick} />
    </div>
  </div>
);

const StatCard = ({ title, value, color }) => (
  <div className={styles.statCard} style={{ borderColor: color }}>
    <p>{title}</p>
    <h3>{value}</h3>
  </div>
);

const Tabs = () => (
  <div className={styles.tabs}>
    <span>Membaca</span>
    <span>Mendengarkan</span>
  </div>
);

const ActivityList = ({ onBookClick }) => (
  <div className={styles.activityList}>
    <ActivityItem 
      title="Data Analysis"
      author="Jolttbook"
      progress="65"
      pages="250"
      status="A Complete Guide to Data Analysis"
      buttonLabel="View"
      onClick={() => onBookClick({ title: "Data Analysis", image: b1,  price:'₹ 550' })}
      bookImage={b1}
      price='₹ 550'
    />
    <ActivityItem 
      title="W.W.W"
      author="Jolttbook"
      progress="100"
      pages="250"
      status="A book for Web Development"
      buttonLabel="View"
      onClick={() => onBookClick({ title: "W.W.W", image: b2,  price:'₹ 650' })}
      bookImage={b2}
      price='₹ 650'
    />
    <ActivityItem 
      title="DB-World"
      author="Jolttbook"
      progress="100"
      pages="250"
      status="A Notes for Data Structure"
      buttonLabel="View"
      onClick={() => onBookClick({ title: "DB-World", image: b3,  price:'₹ 650' })}
      bookImage={b3}
      price='₹ 650'
    />
    <ActivityItem 
      title="Design Develop Deploy"
      author="Jolttbook"
      progress="100"
      pages="250"
      status="A book for Mobile Application Development"
      onClick={() => onBookClick({ title: "Design Develop Deploy", image: b4, price:'₹ 950' })}
      bookImage={b4}
      price='₹ 950'
    />
  </div>
);

const ActivityItem = ({ title, author, progress, pages, status, onClick, bookImage, price }) => (
  <div className={styles.activityItem} onClick={onClick}>
    <div className={styles.bookInfo}>
      <div className={styles.bookImage}>
        <img className={styles.bookImage} src={bookImage} alt={title} />
      </div>
      <div className={styles.bookDetails}>
        <p>{title}</p>
        <small>{author}</small>
      </div>
    </div>
    <div className={styles.bookProgress}>
      <span>{status} </span>
      <span>{progress} Left</span>
      <span>{pages} Pages</span>
      <button>{price}</button>
    </div>
  </div>
);

const RightPanel = ({ selectedBook, isFormVisible, setIsFormVisible }) => (
  <div className={styles.rightPanel}>
    {selectedBook ? (
      <>
        <PaymentCheckout 
          book={selectedBook.title} 
          bookImage={selectedBook.image}
          author={selectedBook.author}
          price={selectedBook.price} 
          onProceed={() => setIsFormVisible(true)} 
        />
        {isFormVisible && <PaymentForm />}
      </>
    ) : (
      <div className={styles.noSelection}>
        <p>Please select a book to continue to checkout.</p>
      </div>
    )}
  </div>
);

const PaymentCheckout = ({ book, bookImage, author, price, onProceed }) => (
  <div className={styles.paymentCheckout}>
    <h2>Checkout</h2>
    <div className={styles.bookInfo}>
      <div className={styles.bookImage}>
        <img className={styles.bookImage} src={bookImage} alt={book} />
      </div>
      <div className={styles.bookDetails}>
        <p>{book}</p>
        <small>{author}</small> 
      </div>
    </div>
    <div className={styles.paymentDetails}>
      <p>Total Price: {price}</p>
      <button onClick={onProceed}>Proceed to Payment</button>
    </div>
  </div>
);

const PaymentForm = () => (
  <div className={styles.paymentForm}>
    <h3>Payment Details</h3>
    <form>
      <div className={styles.formGroup}>
        <label>Full Name</label>
        <input type="text" placeholder="Enter your full name" required />
      </div>
      <div className={styles.formGroup}>
        <label>Email</label>
        <input type="email" placeholder="Enter your email" required />
      </div>
      <div className={styles.formGroup}>
        <label>Phone No.</label>
        <input type="tel" placeholder="Enter your phone number" required />
      </div>
      <button type="submit">Checkout</button>
    </form>
  </div>
);

export default Dashboard;
