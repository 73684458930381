// src/Components/Pages/TermsAndConditions.js
import React from 'react';
import styles from '../Dashboard.module.css'; // Ensure this path is correct
import Sidebar from '../Components/Module/Sidebar';
import Footer from '../Components/Module/Footer';

const TermsAndConditions = () => {
  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <div className={styles.mainContent}>
        <h1>Terms and Conditions</h1>
        <div className={styles.policyContent}>
          <p>Welcome to Jolttbook. These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or purchasing from Jolttbook, you agree to comply with and be bound by the following terms. Please read them carefully.</p>
          
          <h2>1. Acceptance of Terms</h2>
          <p>By using Jolttbook’s website, purchasing an eBook, or accessing our services, you agree to these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you must not use our website.</p>
          
          <h2>2. Eligibility</h2>
          <p>You must be at least 18 years old or have parental consent to use Jolttbook and make purchases. By using the website, you represent and warrant that you meet this requirement.</p>
          
          <h2>3. Account Registration</h2>
          <p>To purchase an eBook, you may need to create an account. You are responsible for maintaining the confidentiality of your account details and password. You agree to notify us immediately of any unauthorized use of your account. Jolttbook is not responsible for any loss or damage resulting from your failure to protect your account.</p>
          
          <h2>4. Purchases and Payments</h2>
          <p>All purchases made through Jolttbook are final, except as outlined in our Refund Policy. You agree to provide accurate and complete payment information for all transactions. Jolttbook reserves the right to refuse or cancel orders at any time due to errors in pricing, availability, or suspected fraudulent activity.</p>
          
          <h2>5. Pricing and Availability</h2>
          <p>All prices on Jolttbook are displayed in [Insert Currency]. Prices and availability of eBooks may change without notice. We reserve the right to modify or discontinue any product at any time.</p>
          
          <h2>6. License and Use of eBooks</h2>
          <p>When you purchase an eBook from Jolttbook, you are granted a limited, non-exclusive, non-transferable license to download and access the eBook for personal, non-commercial use. You are prohibited from:</p>
          <ul>
            <li>Sharing, distributing, or reselling the eBook to others.</li>
            <li>Copying, modifying, or creating derivative works of the eBook.</li>
            <li>Using the eBook for commercial purposes or unauthorized distribution.</li>
          </ul>
          <p>Any unauthorized use may result in the termination of your access to Jolttbook and legal action.</p>
          
          <h2>7. Intellectual Property</h2>
          <p>All content on Jolttbook, including but not limited to eBooks, images, graphics, logos, and text, is the intellectual property of Jolttbook or its licensors. You may not use, reproduce, or distribute any content from our website without prior written consent.</p>
          
          <h2>8. Refunds</h2>
          <p>Due to the nature of digital products, all sales are final. Refunds are only available under specific circumstances, such as duplicate purchases or technical issues, as outlined in our Refund Policy.</p>
          
          <h2>9. User Conduct</h2>
          <p>By using Jolttbook, you agree not to:</p>
          <ul>
            <li>Engage in any unlawful or fraudulent activities.</li>
            <li>Upload or distribute viruses, malware, or harmful code.</li>
            <li>Interfere with or disrupt the functionality of the website.</li>
            <li>Attempt to gain unauthorized access to Jolttbook’s servers or systems.</li>
          </ul>
          <p>Violation of these terms may result in the suspension or termination of your account and access to our services.</p>
          
          <h2>10. Third-Party Links</h2>
          <p>Jolttbook may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.</p>
          
          <h2>11. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, Jolttbook is not liable for any indirect, incidental, special, or consequential damages arising from your use of the website or purchase of eBooks, even if we have been advised of the possibility of such damages.</p>
          
          <h2>12. Indemnification</h2>
          <p>You agree to indemnify and hold harmless Jolttbook, its affiliates, employees, and partners from any claims, losses, liabilities, or expenses arising from your violation of these Terms or your misuse of our services.</p>
          
          <h2>13. Changes to Terms and Conditions</h2>
          <p>Jolttbook reserves the right to modify or update these Terms at any time. Any changes will be posted on this page with the effective date. Your continued use of Jolttbook after such changes constitutes your acceptance of the new Terms.</p>
          
          <h2>14. Termination</h2>
          <p>Jolttbook may terminate or suspend your access to the website and services at our sole discretion, without prior notice, if you violate these Terms or engage in any activity that may harm Jolttbook or its users.</p>
          
          <h2>15. Contact Information</h2>
          <p>For any questions or concerns regarding these terms and conditions, please contact our support team at:</p>
          <p>Email: <a href="mailto:jolttbook@gmail.com">jolttbook@gmail.com</a></p>
          <p>Address: 438/74 Patel Marg, Ramgarh, Ghaziabad, Uttar Pradesh, 201001</p>
          <p>OPERATED BY: Kulwinder Kaur</p>
          <p>Instagram: [Insert Instagram Link]</p>
          <p>Contact Us: 9140293443</p>
          <p>For more queries contact: 7460802449</p>
          
          <p>© 2024 Jolttbook. All rights reserved.</p>
        </div>
      </div>
     
    </div>
  );
};

export default TermsAndConditions;
