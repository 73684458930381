import React from 'react';
import styles from '../Dashboard.module.css'; // Assuming this file has shared styles
import Sidebar from '../Components/Module/Sidebar';

const ContactUs = () => {
  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <div className={styles.mainContent}>
        <div className={styles.contactContainer}>
          <div className={styles.contactForm}>
            <h1>Contact Us</h1>
            <form className={styles.form}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />

              <label htmlFor="phone">Phone Number:</label>
              <input type="tel" id="phone" name="phone" required />

              <label htmlFor="description">Description:</label>
              <textarea id="description" name="description" rows="4" required></textarea>

              <button type="submit">Send Message</button>
            </form>
          </div>
          <div className={styles.contactInfo}>
            <h2>Contact Information</h2>
            <p><strong>Email:</strong> <a href="mailto:jolttbook@gmail.com">jolttbook@gmail.com</a></p>
            <p><strong>Phone:</strong> <a href="tel:+1234567890">+91 9118847494</a></p>
            <p><strong>Office Address:</strong> 438/74 Patel Marg, Ramgarh, Ghaziabad, Uttar Pradesh, 201001</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
