import { Link } from 'react-router-dom';
import styles from '../../Dashboard.module.css';
import home from '../icons/home.png';
import about from '../icons/about.png';
import contact from '../icons/contact.png';
import refund from '../icons/refund.png';
import terms from '../icons/terms.png';
import privacy from '../icons/privacy.png';
import { useState } from 'react';
import React from 'react';

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div 
      className={`${styles.sidebar} ${isExpanded ? styles.expanded : ''}`} 
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Link to="/" className={styles.menuItem}>
        <img className={styles.menuImg} src={home} alt="/" /> {isExpanded && <span className={styles.menuText}>Home</span>}
      </Link>
      <Link to="/about" className={styles.menuItem}>
        <img className={styles.menuImg} src={about} alt="About Us" /> {isExpanded && <span className={styles.menuText}>About Us</span>}
      </Link>
      <Link to="/refund" className={styles.menuItem}>
        <img className={styles.menuImg} src={refund} alt="Refund Policy" /> {isExpanded && <span className={styles.menuText}>Refund Policy</span>}
      </Link>
      <Link to="/terms" className={styles.menuItem}>
        <img className={styles.menuImg} src={terms} alt="Terms & Conditions" /> {isExpanded && <span className={styles.menuText}>Terms & Conditions</span>}
      </Link>
      <Link to="/privacy" className={styles.menuItem}>
        <img className={styles.menuImg} src={privacy} alt="Privacy Policy" /> {isExpanded && <span className={styles.menuText}>Privacy Policy</span>}
      </Link>
      <Link to="/contact" className={styles.menuItem}>
        <img className={styles.menuImg} src={contact} alt="Contact Us" /> {isExpanded && <span className={styles.menuText}>Contact Us</span>}
      </Link>
      <div className={styles.profilePic}>
        <span>👤</span>
        {isExpanded && <span>User Name</span>}
      </div>
    </div>
  );
};

export default Sidebar;
